import React, { Suspense, useEffect, useState, lazy } from "react";

// Libraries
import { Routes, Route, useLocation } from "react-router-dom";
import retina from "retinajs";
import { AnimatePresence } from "framer-motion";

// Context
import GlobalContext from "./Context/Context";

// Components
import ScrollToTopButton from "./Components/ScrollToTop"

import ReactGA from "react-ga4";
ReactGA.initialize("G-3CD8NSTGJK");

// Pages
const Home = lazy(() => import("./Pages/Home"))
const WhoWeAre = lazy(() => import("./Pages/WhoWeAre"))
const TAAS = lazy(() => import("./Pages/TAAS"))
const C2C = lazy(() => import("./Pages/C2C"))
const AAI = lazy(() => import("./Pages/AAI"))

const WhyRRT = lazy(() => import("./Pages/WhyRRT"))
const GetInTouch = lazy(() => import("./Pages/GetInTouch"))
//
const CreativeTalent = lazy(() => import("./Pages/CreativeTalent"))
const MarketingTalent = lazy(() => import("./Pages/MarketingTalent"))
const DataTalent = lazy(() => import("./Pages/DataTalent"))
const TechTalent = lazy(() => import("./Pages/TechTalent"))
//
const ForAgencies = lazy(() => import("./Pages/ForAgencies"))
const ForBrands = lazy(() => import("./Pages/ForBrands"))
//
const Privacy = lazy(() => import("./Pages/Privacy"))
const Terms = lazy(() => import("./Pages/Terms"))
const Credits = lazy(() => import("./Pages/Credits"))

function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customModal, setCustomModal] = useState({
    el: null,
    isOpen: false
  })
  const location = useLocation();

  // RetinaJS
  useEffect(() => {
    window.addEventListener('load', retina(document.querySelectorAll('img')));
  }, [])

  useEffect(() => {
    setTimeout(() => {
      import("./Functions/Utilities").then(module => {
        module.SetHeaderMenuPos()
        module.setDocumentFullHeight()
      })
    }, 1000);
  }, [location])

  useEffect(() => {
    if (isModalOpen === true) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  // Get the current location and set the window to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setFooterHeight(0);
    setCustomModal({
      ...customModal,
      el: null,
      isOpen: false
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <GlobalContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        footerHeight,
        setFooterHeight,
        isModalOpen,
        setIsModalOpen,
        customModal,
        setCustomModal,
      }}
    >
      <div className="App" style={{ "--header-height": `${headerHeight}px` }}>
        {
          <main style={{ marginTop: headerHeight, marginBottom: footerHeight }}>
            <ScrollToTopButton />
            <AnimatePresence exitBeforeEnter>
              <Suspense fallback={<></>}>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/who-we-are" element={<WhoWeAre />} />
                  <Route path="/talent-as-a-service" element={<TAAS />} />
                  <Route path="/agentic-ai" element={<AAI />} />
                  <Route path="/concept-to-completion" element={<C2C />} />
                  <Route path="/why-rrt" element={<WhyRRT />} />
                  <Route path="/get-in-touch" element={<GetInTouch />} />

                  <Route path="/for-agencies" element={<ForAgencies />} />
                  <Route path="/for-brands" element={<ForBrands />} />

                  <Route path="/creative-service" element={<CreativeTalent />} />
                  <Route path="/marketing-service" element={<MarketingTalent />} />
                  <Route path="/data-service" element={<DataTalent />} />
                  <Route path="/tech-service" element={<TechTalent />} />

                  <Route path="/privacy" element={<Privacy />} />
                  <Route path="/terms" element={<Terms />} />
                  <Route path="/credits" element={<Credits />} />

                </Routes>
              </Suspense>
            </AnimatePresence>
          </main>
        }
      </div>
    </GlobalContext.Provider>
  )
}

export default App;